import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Link, SEO } from 'components';
import { routes } from 'utils/routes';

import content from './terms-of-use.yml';

const TermsPage = ({ pageContext: { locale }, location }) => (
  <Layout locale={locale}>
    <SEO
      title={content.title}
      description={content.description}
      lang={locale}
      keywords={content.keywords}
      location={location}
      image={content.socialImage}
    />

    <div className='text-wrapper mt-90 mb-90'>
      <h1 className='section-title mb-50'>Straight Teeth Direct&trade;<br/>Terms of use</h1>

      <p>Straightteethdirect.com is a website operated by Straight Teeth Direct Europe Ltd incorporated and registered in Ireland.</p>

      <h3 className='mt-40 mb-20'>Terms of Use</h3>
      <p>This page tells you the terms of use (“Terms”) on which you may make use of our website, <Link to='www.straightteethdirect.com' external>www.straightteethdirect.com</Link>, (our “Site”) or any application we make available via an app store or otherwise (our “Service”), whether as a guest or a registered user. Please read these Terms carefully before you start to use or Site or our Service. By accessing our Site or by using our Service, you indicate that you accept these Terms and that you agree to abide by them. If you do not agree to these Terms, do not use access our Site or use our Service.</p>

      <h3 className='mt-40 mb-20'>Accessing Our Site or Our Services</h3>
      <p>Access to our Site and to our Service is permitted and we reserve the right to withdraw or amend access to our Site or our Service without notice. We will not be liable if, for any reason, our Site or our Service is unavailable at any time or for any period. From time to time, we may restrict access to some parts our Site or our Service, or our entire Site or Service to users who have registered with us. You are responsible for maintaining the confidentially of your login details and any activities that occur under your account. If you have any concerns about your login details or think they have been misused, you should contact <b>hello@straightteethdirect.com</b> straight away to let us know.</p>

      <h3 className='mt-40 mb-20'>Use of the Service</h3>
      <p>You may view and use the Site and Service information only for your personal information and for ordering on the Site and Service.  The Site and Service does not grant to you any right to use, copy, transfer, display, publish, sell, license, create derivative works or distribute by any means, method or process any Site and Service Information. Your right to use the Site and Service is personal to you. You are responsible for all use of the Site and Service. You agree to use the Site and Services only for lawful purposes and you acknowledge that failure may be subject to liability.</p>
      <p>You agree not to use the Site or Service to do the following:</p>
      <p><b>(a)</b> upload, post or otherwise transmit any material that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libellous, invasive of another’s  privacy, hateful or racially, ethnically or otherwise objectionable;</p>
      <p><b>(b)</b> upload, post or otherwise transmit any material that you do not have the right to transmit under, or infringe, any law (including with intellectual property law) or contractual or fiduciary relationship;</p>
      <p><b>(c)</b> post irrelevant material or repeatedly post the same or similar material unduly;</p>
      <p><b>(d)</b> upload, post or otherwise transmit any unauthorised advertising, promotional materials, ‘junk mail’ or spam or any form of solicitation, except in those areas of the Website designated for such purposes;</p>
      <p><b>(e)</b> anything that is in violation of any applicable law, rule or regulation;</p>
      <p><b>(f)</b> transmit viruses, malware, or other malicious code.</p>

      <p>You also agree to comply with the clinical recommendations given to you during the use of the Service. Non compliance, threats or hostile messages to the doctors or the support team are against our terms of use and can lead to suspension and discontinuation.</p>

      <h3 className='mt-40 mb-20'>Intellectual Property Rights</h3>
      <p>We are the owner of or the licensee of all intellectual property rights in our Site and Service, and in the material published on it (excluding your Contributions). Those works are protected by copyright laws and treaties around the world. All such rights are reserved. You may not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use any content on our site in any way except for your own personal, non-commercial use.</p>
      <p>If you submit comments or content of any nature whatsoever (“User Content”) to us, you agree and warrant that you created the User Content or have received permission from, or are duly authorised by, the owner of any part of the User Content to do so.</p>

      <h3 className='mt-40 mb-20'>Suspension And Discontinuation</h3>
      <p>Your failure to follow the requirements of these Terms may result in suspension or discontinuation of your access to the Services, without notice. Straight Teeth Direct Europe Ltd further reserves the right to discontinue, without notice, any user’s access to or use of the Site and Service for any reason.  Following termination all such terms that by their nature may survive termination of these Terms shall be deemed to survive such termination.</p>

      <h3 className='mt-40 mb-20'>Acceptable Use</h3>
      <p>The Site and Service is for the personal use of individuals only and may not be used in connection with any commercial ventures. Companies and/or businesses may not use the Services, the Site and Service Information or the Site for any purpose without written consent of or agreement with Straight Teeth Direct Europe Ltd. You agree not to make use of Site and Service Information by publication, distribution, performance or otherwise, except as permitted in writing by Straight Teeth Direct Europe Ltd.</p>

      <h3 className='mt-40 mb-20'>Your rights to Use the Site and the Services</h3>
      <p>The Site and Service are not intended for users under the age of 16. To register for any Services offered on the Site and Service, you must be 16 years of age or older. If you are a minor under the age of 18, you may only use the Site and Service in conjunction with your parents or guardians.</p>

      <h3 className='mt-40 mb-20'>Billing</h3>
      <p>After selecting a purchase I authorise STRAIGHT TEETH DIRECT EUROPE LTD to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.</p>

      <h3 className='mt-40 mb-20'>Limitation of Liability</h3>
      <p>To the extent permitted by law, Straight Teeth Direct Europe Ltd provides the services and content on an “as-is” and “as available” basis and we make no representation or warranty of any kind, express or implied, regarding the content or availability of our Site and Service, or that it will be timely or error-free or that defects will be corrected. Subject as provided below, neither Straight Teeth Direct Europe Ltd nor any its affiliates and its third party service  shall have any liability to you for any direct, indirect, special or consequential losses or damages arising in contract, tort (including negligence) or otherwise arising from your use of or your inability to use our Site and/or Service.</p>
      <p>Straight Teeth Direct Europe Ltd , its affiliates, and its third party service providers do not represent or warrant that access to the site and its services or that there will be no failures, errors or loss or security breach of transmitted information, or that no viruses will be transmitted through access to or use of Straight Teeth Direct.com.</p>
      <p>As a convenience, the Site or Services may contain links to other websites which are maintained and operated by third parties.  The linked websites are not under our control, and we are not responsible for the content available on any other websites linked to the Site or Services.  Such links do not imply our endorsement, sponsorship or recommendation of material on any other website and we disclaim any warranties (express or implied) about the content and operation of these websites.  We disclaim any liability for links: (i) from another website to the Site; and (ii) to another website from the Site or Services.</p>
      <p>You agree to indemnify and hold Straight Teeth Direct Europe Ltd, its officers and employees, harmless from any claims, damages, expenses, liabilities, costs or demands, including legal expenses, made or brought by a third party due to or arising out of (a) your use of the Site or Services or any part thereof; (b) the violation of these Terms or any provision therein by you; and (c) any intellectual property rights of any person or entity.</p>

      <h3 className='mt-40 mb-20'>Jurisdiction and Governing Law</h3>
      <p>The Irish courts will have exclusive jurisdiction over any claim arising from, or related to, a visit to our Site or Services.  The place of performance of these Terms will be Ireland.  These Terms and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of Ireland.</p>
      <p>Straight Teeth Direct Europe Ltd makes no representation that the Site and Service Information, the Services or products offered through the Site are appropriate, available or legal in any particular location. Those who choose to access the Site and Service Information, the Services and products offered through the Site do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable.</p>

      <h3 className='mt-40 mb-20'>Electronic Communication</h3>
      <p>When you visit the Site and Service or send emails to us, you are communicating with us electronically. You consent to receive communications from us electronically. We will communicate with you by email. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>

      <h3 className='mt-40 mb-20'>Trademarks</h3>
      <p>Straight Teeth Direct Europe Ltd and the Site names, logos, and other identifying marks are the property of Straight Teeth Direct Ltd.</p>

      <h3 className='mt-40 mb-20'>Severability</h3>
      <p>If any of these Terms should be determined to be illegal, invalid or otherwise unenforceable, such Term or Terms shall be severed and deleted from the clause concerned and the remaining Terms shall survive, remain in full force and effect and continue to be binding and enforceable.</p>

      <h3 className='mt-40 mb-20'>Privacy Policy</h3>
      <p>We collect certain data about you as a result of you using our Service. This is described in more detail in our Privacy Policy.</p>
      <p>In addition to reviewing these Terms you should also read our <Link to={routes.privacy.path} external={routes.privacy.external}>Privacy Policy</Link>. Your use of the Site constitutes your agreement to the terms of the Privacy Policy.</p>
    </div>
  </Layout>
);

TermsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default TermsPage;
